import { Button, Form, Col, Row, Spinner } from "react-bootstrap";
import { CCard, CCardHeader, CCardBody, CAlert } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import AmenitiesCheckBox from "../../rooms-page/components/AmenitiesCheckBox";
import { Api } from "../../../services/axios.service";
import HotelLocationPicker from "../components/HotelLocationPicker";
import CoupleFriendly from "./components/CoupleFriendly";
import AddHotelImagesModal from "./components/AddHotelImagesModal";
import AddPlaceModal from "./components/AddPlaceModal";
import AddRestaurantsModal from "./components/AddRestaurantsModal";
import PoliciesCheckBox from "./components/PoliciesCheckBox";

const EditHotelPage = () => {
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState<any>("");
  const [formSuccess, setFormSuccess] = useState("");
  const [image, setImage] = useState<any>("");
  const [editData, setEditData] = useState("");
  const [amenity_data, setAmenityData] = useState<any[]>([]);
  const [vendor_list, setVendorList] = useState<any[]>([]);
  // const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [hotel_name, setHotelName] = useState("");
  const [vendor, setVendor] = useState("");
  const [hotel_address, setHotelAddress] = useState("");
  const [hotel_description, setHotelDescription] = useState("");
  const [check_in_time, setCheckInTime] = useState("");
  const [check_out_time, setCheckOutTime] = useState("");
  const [amenities, setAmenities] = useState<any[]>([]);
  const [couple_friendly, setCoupleFriendly] = useState(false);
  const [policies, setPolicies] = useState<any[]>([]);
  const [policy_list, setPolicyList] = useState<any[]>([]);
  const [state_list, setStateList] = useState<any[]>([]);
  const [city_list, setCityList] = useState<any[]>([]);
  const [places_list, setPlacesList] = useState<any[]>([]);
  const [places_loading, setPlacesLoading] = useState(true);
  const [restaurant_list, setRestaurantList] = useState<any[]>([]);
  const [restaurant_loading, setRestaurantLoading] = useState(true);
  const [selectState_loading, setSelectStateLoading] = useState(true);
  const [selectCity_loading, setSelectCityLoading] = useState(true);
  const [state, setState] = useState(0);
  const [city, setCity] = useState(0);
  const [country, setCountry] = useState("");
  const [plateform_fee, setPlatformFee] = useState("");
  const [commision, setCommission] = useState("");
  const [convenience_fee, setConvenienceFee] = useState("");
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [placeModal, setPlaceModal] = useState(false);
  const [restaurantModal, setRestaurantModal] = useState(false);
  const [partial_payment, setPartialPayment] = useState(false);
  const [pay_at_hotel, setPayAtHotel] = useState(false);
  const [pay_online, setPayOnline] = useState(false);

  const { id } = useParams();

  const togglePlaceModal = () => {
    setPlaceModal(!placeModal);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const partial_payment = (e.target as any).partial_payment.checked;
    const pay_at_hotel = (e.target as any).pay_at_hotel.checked;
    const pay_online = (e.target as any).pay_online.checked;

    if (!(partial_payment || pay_at_hotel || pay_online)) {
      setFormError({ payment_option: "Payment option field is required" });
      return;
    }

    const formData = new FormData(e.target as HTMLFormElement);

    let token = localStorage.getItem("token");
    console.log("e.target", e.target);

    console.log("allamenitiesArray", (e.target as any).amenities.value);
    let allamenitiesArray = (e.target as any).amenities;
    for (let item of allamenitiesArray) {
      console.log("checked-all-amenities", item.checked);
      console.log("item-all-amenities", item.value);
      console.log("-------------End-----------------");

      if (item.checked) {
        formData.append("amenities[]", item.value);
      }
    }

    let allpoliciesArray = (e.target as any).policies;
    for (let item of allpoliciesArray) {
      if (item.checked) {
        formData.append("policies[]", item.value);
      }
    }

    formData.append("token", token!);
    formData.append("id", id ?? "");
    formData.append("vendor", (e.target as any).vendor.value);
    formData.append("hotel_name", (e.target as any).hotel_name.value);
    formData.append("hotel_address", (e.target as any).hotel_address.value);
    formData.append("city", (e.target as any).city.value);
    formData.append("state", (e.target as any).state.value);
    formData.append("country", (e.target as any).country.value);
    formData.append(
      "hotel_description",
      (e.target as any).hotel_description.value
    );
    formData.append("couple_friendly", (e.target as any).couple_friendly.value);
    formData.append("check_in_time", (e.target as any).check_in_time.value);
    formData.append("check_out_time", (e.target as any).check_out_time.value);
    formData.append("plateform_fee", (e.target as any).plateform_fee.value);
    formData.append("commision", (e.target as any).commision.value);
    formData.append("convenience_fee", (e.target as any).convenience_fee.value);
    formData.append("lat", (e.target as any).lat.value);
    formData.append("lng", (e.target as any).lng.value);
    formData.append("partial_payment", partial_payment.toString());
    formData.append("pay_at_hotel", pay_at_hotel.toString());
    formData.append("pay_online", pay_online.toString());

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Api.post("Admin/hotel/update", formData, config).then((res) => {
      window.scrollTo(0, 0);
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
      } else {
        setFormError(res.data["formErrors"]);
      }
    });
  };

  useEffect(() => {
    loadHotelData();
    loadStates();
    loadAmenities();
    loadHotelPlaces();
    loadHotelRestaurants();
  }, []);

  const loadAmenities = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token!);
    Api.post("Admin/amenities", formData).then((res) => {
      if (res.data["status"] === "success") {
        setAmenityData(res.data["data"]);
        setLoading(false);
      } else {
        setAmenityData([]);
      }
    });

    Api.post("Admin/vendors", formData).then((res) => {
      if (res.data["status"] === "success") {
        setVendorList(res.data["data"]);
        setLoading(false);
      } else {
        setVendorList([]);
      }
    });

    Api.post("Admin/policy", formData).then((res) => {
      if (res.data["status"] === "success") {
        setPolicyList(res.data["data"]);
        setLoading(false);
      } else {
        setPolicyList([]);
      }
    });
  };

  const loadHotelData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token!);
    formData.append("id", id ?? "");

    Api.post("Admin/hotel/edit_hotel", formData).then((res) => {
      if (res.data["status"] === "success") {
        let editData = res.data["data"];

        setEditData(editData);
        setHotelName(editData.hotel_name);
        setHotelAddress(editData.address);
        setAmenities(editData.amenities);
        setCoupleFriendly(editData.couple_friendly);
        setPolicies(editData.policy_rules);
        setHotelDescription(editData.hotel_description);
        setCheckInTime(editData.check_in_time);
        setCheckOutTime(editData.check_out_time);
        setVendor(editData.vendor_id);
        setState(editData.state);
        setCity(editData.city);
        setCountry(editData.country);
        setPlatformFee(editData.plateform_fee);
        setCommission(editData.commision);
        setConvenienceFee(editData.convenience_fee);
        setLat(editData.lat);
        setLng(editData.lng);
        setPartialPayment(Number(editData.partial_payment) === 1);
        setPayAtHotel(Number(editData.pay_at_hotel) === 1);
        setPayOnline(Number(editData.pay_online) === 1);
        loadStateCity(editData.state);
      } else {
        setEditData("");
      }
    });
  };

  const loadStates = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token!);
    Api.post("Admin/region/get_states", formData).then((res) => {
      if (res.data["status"] === "success") {
        setStateList(res.data["data"]);
        setSelectStateLoading(false);
      } else {
        setStateList([]);
      }
    });
  };

  const loadStateCity = (state: any) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token!);
    formData.append("state_id", state.toString());
    Api.post("Admin/region/get_cities_by_state_id", formData).then((res) => {
      if (res.data["status"] === "success") {
        setCityList(res.data["data"]);
        setSelectCityLoading(false);
      } else {
        setCityList([]);
      }
    });
  };

  const loadHotelPlaces = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token!);
    formData.append("hotel_id", id ?? "");
    Api.post("Admin/places", formData).then((res) => {
      if (res.data["status"] === "success") {
        setPlacesList(res.data["data"]);
        setPlacesLoading(false);
      } else {
        setPlacesList([]);
      }
    });
  };

  const deletePlace = (id: number) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token!);
    formData.append("id", id.toString());
    Api.post("Admin/places/delete", formData).then((res) => {
      if (res.data["status"] === "success") {
        loadHotelPlaces();
      } else {
        loadHotelPlaces();
      }
    });
  };

  const loadHotelRestaurants = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token!);
    formData.append("hotel_id", id ?? "");
    Api.post("Admin/Restaurants", formData).then((res) => {
      if (res.data["status"] === "success") {
        setRestaurantList(res.data["data"]);
        setRestaurantLoading(false);
      } else {
        setRestaurantList([]);
      }
    });
  };

  const deleteRestaurants = (id: number) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token!);
    formData.append("id", id.toString());
    Api.post("Admin/Restaurants/delete", formData).then((res) => {
      if (res.data["status"] === "success") {
        loadHotelRestaurants();
      } else {
        loadHotelRestaurants();
      }
    });
  };

  let error = formError as any;
  return (
    <div>
      <CCard>
        <CCardHeader>
          <h4>Edit Hotel</h4>
        </CCardHeader>
        <CCardBody>
          {formSuccess !== "" ? (
            <div>
              <CAlert color="success">{formSuccess}</CAlert>
            </div>
          ) : (
            <div />
          )}
          <Form encType={"multipart/form-data"} onSubmit={handleSubmit}>
            <Row className="form-sections">
              <Col lg="12">
                <h4>Hotel Information </h4>
                <Form.Group>
                  <Form.Label>Hotel Name</Form.Label>
                  <Form.Control
                    name="hotel_name"
                    type="text"
                    placeholder="Enter hotel name"
                    value={hotel_name}
                    onChange={(e) => setHotelName(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {error?.hotel_name}
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Hotel Address</Form.Label>
                  <Form.Control
                    name="hotel_address"
                    type="text"
                    placeholder="Enter hotel address"
                    value={hotel_address}
                    onChange={(e) => setHotelAddress(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {error?.hotel_address}
                  </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Vendor</Form.Label>
                  <Form.Control name="vendor" as="select" custom>
                    <option value="" selected>
                      --- select vendor ---
                    </option>

                    {vendor_list.map((list, index) => {
                      let selected = "";

                      list.id === vendor
                        ? (selected = "selected")
                        : (selected = "");

                      return (
                        <option
                          selected={selected ? true : undefined}
                          key={index.toString()}
                          value={list.id}
                        >
                          {list.full_name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <div className="invalid-feedback d-block">
                    {error?.vendor}
                  </div>
                </Form.Group>
              </Col>
              <HotelLocationPicker lat={lat} lng={lng} />
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <h4>Hotel Places </h4>
              </Col>
              <Col lg="12">
                <div className="my-2">
                  <Button
                    onClick={() => {
                      setPlaceModal(true);
                    }}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faPlus as any} />
                    Add Place
                  </Button>
                </div>

                {places_loading ? (
                  <div className="text-center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  places_list.map((place, index) => {
                    return (
                      <div className="card p-3 my-2">
                        <div className="text-right">
                          <Button
                            onClick={() => {
                              deletePlace(place.id);
                            }}
                            variant={"link"}
                          >
                            <FontAwesomeIcon
                              className="text-danger"
                              icon={faTimes as any}
                            />
                          </Button>
                        </div>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Place Id</Form.Label>
                              <Form.Control
                                readOnly
                                name="place_id"
                                type="number"
                                placeholder="Enter place id"
                                // onChange={(e) => setPlace}
                                value={place.place_id}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Place Name</Form.Label>
                              <Form.Control
                                readOnly
                                name="place_name"
                                type="text"
                                placeholder="Enter place name"
                                // onChange={handleChange}
                                value={place.place_name}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Place Lat</Form.Label>
                              <Form.Control
                                readOnly
                                name="place_lat"
                                type="number"
                                placeholder="Enter category name"
                                onChange={(e) =>
                                  setCheckOutTime(e.target.value)
                                }
                                value={place.lat}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Place Long</Form.Label>
                              <Form.Control
                                readOnly
                                name="place_long"
                                type="number"
                                placeholder="Enter category name"
                                onChange={(e) =>
                                  setCheckOutTime(e.target.value)
                                }
                                value={place.long}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                )}
              </Col>
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <h4>Hotel Restaurants </h4>
              </Col>
              <Col lg="12">
                <div className="my-2">
                  <Button
                    onClick={() => {
                      setRestaurantModal(true);
                    }}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faPlus as any} />
                    Add Restaurant
                  </Button>
                </div>

                {restaurant_loading ? (
                  <div className="text-center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  restaurant_list.map((restaurant, index) => {
                    return (
                      <div className="card p-3 my-2">
                        <div className="text-right">
                          <Button
                            onClick={() => {
                              deleteRestaurants(restaurant.id);
                            }}
                            variant={"link"}
                          >
                            <FontAwesomeIcon
                              className="text-danger"
                              icon={faTimes as any}
                            />
                          </Button>
                        </div>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Restaurant Id</Form.Label>
                              <Form.Control
                                readOnly
                                name="restaurant_id"
                                type="number"
                                placeholder="Enter restaurant id"
                                // onChange={handleChange}
                                value={restaurant.restaurant_id}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Restaurant Name</Form.Label>
                              <Form.Control
                                readOnly
                                name="restaurant_name"
                                type="text"
                                placeholder="Enter restaurant name"
                                // onChange={handleChange}
                                value={restaurant.restaurant_name}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Restaurant Lat</Form.Label>
                              <Form.Control
                                readOnly
                                name="check_out_time"
                                type="number"
                                placeholder="Enter category name"
                                onChange={(e) =>
                                  setCheckOutTime(e.target.value)
                                }
                                value={restaurant.lat}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Restaurant Long</Form.Label>
                              <Form.Control
                                readOnly
                                name="check_out_time"
                                type="number"
                                placeholder="Enter restaurant name"
                                onChange={(e) =>
                                  setCheckOutTime(e.target.value)
                                }
                                value={restaurant.long}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                )}
              </Col>
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <h4>Description </h4>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="hotel_description"
                    value={hotel_description}
                    onChange={(e) => setHotelDescription(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {error?.hotel_description}
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <CoupleFriendly couple_friendly={couple_friendly} />
              </Col>
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <h4>Select Amenities </h4>
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <div className="mt-3" key={`inline-checkbox`}>
                    {amenity_data.map((list, index) => {
                      return (
                        <AmenitiesCheckBox
                          key={index.toString()}
                          amenity={list}
                          amenities={amenities}
                        />
                      );
                    })}
                    <div className="invalid-feedback d-block">
                      {error?.amenities}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="form-sections">
              <Col lg="12">
                <h4>Select Region </h4>

                <Row>
                  <Col lg="4">
                    <Form.Group>
                      <Form.Label>Country</Form.Label>
                      <Form.Control name="country" as="select" custom>
                        <option value="" selected>
                          --- select Country ---
                        </option>
                        <option value="India" selected>
                          India
                        </option>
                      </Form.Control>
                      <div className="invalid-feedback d-block">
                        {error?.country}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="4">
                    <Form.Group>
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        name="state"
                        as="select"
                        custom
                        onChange={(event) => {
                          let state = event.target.value;
                          if (state === "") {
                            setCityList([]);
                          } else {
                            loadStateCity(state);
                          }
                        }}
                      >
                        <option value="">--- select State ---</option>

                        {state_list.map((list, index) => {
                          var stateselected = "";
                          if (list.id === state) {
                            stateselected = "selected";
                          } else {
                            stateselected = "";
                          }

                          return (
                            <option
                              selected={stateselected ? true : undefined}
                              key={index.toString()}
                              value={list.id}
                            >
                              {list.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <div className="invalid-feedback d-block">
                        {error?.state}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="4">
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      <Form.Control name="city" as="select" custom>
                        <option value="">--- select city ---</option>

                        {city_list.map((list, index) => {
                          var citySelected = "";
                          if (list.id === city) {
                            citySelected = "selected";
                          } else {
                            citySelected = "";
                          }

                          return (
                            <option
                              selected={citySelected ? true : undefined}
                              key={index.toString()}
                              value={list.id}
                            >
                              {list.city_name}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <div className="invalid-feedback d-block">
                        {error?.city}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <h4>Hotel Check-in / Check-out time </h4>
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>Check-In Time</Form.Label>
                  <Form.Control
                    name="check_in_time"
                    type="time"
                    value={check_in_time}
                    onChange={(e) => setCheckInTime(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {error?.check_in_time}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Check-Out Time</Form.Label>
                  <Form.Control
                    name="check_out_time"
                    type="time"
                    placeholder="Enter category name"
                    value={check_out_time}
                    onChange={(e) => setCheckOutTime(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {error?.check_out_time}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-sections">
              <Col lg="12">
                <h4>Select Hotel Policy & Rules </h4>
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <div className="mt-3" key={`inline-checkbox`}>
                    {policy_list.map((list, index) => {
                      return (
                        <PoliciesCheckBox
                          key={index.toString()}
                          policy={list}
                          policies={policies}
                        />
                      );
                    })}
                    <div className="invalid-feedback d-block">
                      {error?.policies}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <h4>Other Fees </h4>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>convenience fee</Form.Label>
                  <Form.Control
                    name="convenience_fee"
                    type="text"
                    placeholder="Enter convenience fee"
                    value={convenience_fee}
                    onChange={(e) => setConvenienceFee(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {error?.convenience_fee}
                  </div>
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>
                    Commission <small>(in %)</small>
                  </Form.Label>
                  <Form.Control
                    name="commision"
                    type="text"
                    placeholder="Enter Commission "
                    value={commision}
                    onChange={(e) => setCommission(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {error?.commision}
                  </div>
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>
                    {" "}
                    Platform fee <small>(in %)</small>
                  </Form.Label>
                  <Form.Control
                    name="plateform_fee"
                    type="text"
                    placeholder="Enter Platform fee"
                    value={plateform_fee}
                    onChange={(e) => setPlatformFee(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {error?.plateform_fee}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-sections">
              <Col lg="12">
                <h4>Payment Options</h4>
                <div className="mt-3" key={`inline-checkbox`}>
                  <Form.Check
                    checked={partial_payment}
                    onChange={(e) => {
                      setPartialPayment(e.target.checked);
                    }}
                    value="Pay 10% now and rest at hotel"
                    name="partial_payment"
                    className="mycheckbox"
                    label={"Pay 10% now and rest at hotel"}
                    type={"checkbox"}
                    id={`inline-checkbox-${"pay 10% now and rest at hotel"}`}
                  />
                </div>
                <div className="mt-3" key={`inline-checkbox`}>
                  <Form.Check
                    checked={pay_at_hotel}
                    onChange={(e) => {
                      setPayAtHotel(e.target.checked);
                    }}
                    name="pay_at_hotel"
                    className="mycheckbox"
                    label={"book now & pay at hotel"}
                    type={"checkbox"}
                    id={`inline-checkbox-${"book now & pay at hotel"}`}
                  />
                </div>
                <div className="mt-3" key={`inline-checkbox`}>
                  <Form.Check
                    checked={pay_online}
                    onChange={(e) => {
                      setPayOnline(e.target.checked);
                    }}
                    name="pay_online"
                    className="mycheckbox"
                    label={"Book now & pay online"}
                    type={"checkbox"}
                    id={`inline-checkbox-${"Book now & pay online"}`}
                  />
                </div>
                <div className="invalid-feedback d-block">
                  {error?.payment_option}
                </div>
              </Col>
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <h4>Add Hotel Images </h4>
                <AddHotelImagesModal hotel_id={params.id ?? ""} />
              </Col>
            </Row>

            <Button variant="primary" type="submit">
              Update Hotel
            </Button>
          </Form>
        </CCardBody>
      </CCard>

      <AddPlaceModal
        hotelId={params.id ?? ""}
        togglePlaceModal={togglePlaceModal}
        PlaceModal={placeModal}
        refreshList={loadHotelPlaces}
      />
      <AddRestaurantsModal
        hotelId={params.id ?? ""}
        toggleRestaurentModal={() => setRestaurantModal((e) => !e)}
        RestaurentModal={restaurantModal}
        refreshList={loadHotelRestaurants}
      />
    </div>
  );
};

export default EditHotelPage;
